import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TemporaryLoginCredentials from './TemporaryLoginCredentials';
import AuthContext from "../context/AuthContext";

const Login = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState(null);
  const [error, setError] = useState(null);
  const [polling, setPolling] = useState(false);
  const [isRetrieving, setIsRetrieving] = useState(false);
  const [email, setEmail] = useState('');
  const [retrievalMessage, setRetrievalMessage] = useState('');

  const sessionId = new URLSearchParams(window.location.search).get('session_id');

  useEffect(() => {
    let intervalId = null;

    const pollCredentials = async () => {
      try {
        console.log("Polling for credentials...");
        const response = await fetch(`https://gothos.biz/api/get_temp_credentials?session_id=${sessionId}`);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Received data:", data);

        if (data.status === 'complete') {
          setPolling(false);
          setCredentials(data);
          if (intervalId) {
            clearInterval(intervalId);
          }
        } else if (data.error) {
          setError(data.error);
          setPolling(false);
          if (intervalId) {
            clearInterval(intervalId);
          }
        }
      } catch (error) {
        console.error('Error fetching credentials:', error);
        setError(error.message);
        setPolling(false);
        if (intervalId) {
          clearInterval(intervalId);
        }
      }
    };

    if (sessionId && !credentials?.status) {
      setPolling(true);
      pollCredentials();
      intervalId = setInterval(pollCredentials, 2000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [sessionId, credentials?.status]);

  const handlePasswordRetrieval = async (e) => {
    e.preventDefault();
    setIsRetrieving(true);
    setError(null);
    setRetrievalMessage('');

    try {
      const response = await fetch('https://gothos.biz/api/retrieve_password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });

      const data = await response.json();

      if (response.ok) {
        setRetrievalMessage('If an account exists with this email, you will receive instructions shortly.');
        setEmail('');
      } else {
        setError(data.error || 'Failed to process request');
      }
    } catch (error) {
      setError('Failed to connect to the server');
    } finally {
      setIsRetrieving(false);
    }
  };

  const handleLogin = async () => {
    try {
      console.log("Attempting login with credentials:", credentials);
      const response = await fetch('https://gothos.biz/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username: credentials.username,
          wallet_address: credentials.wallet_address,
          password: credentials.temp_password
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Login failed');
      }

      const data = await response.json();
      await login(data);
      navigate('/dashboard');
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message);
    }
  };

  const togglePasswordRetrieval = () => {
    setIsRetrieving(!isRetrieving);
    setError(null);
    setRetrievalMessage('');
  };

  return (
    <div className="min-h-screen flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-6">
        {error && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4">
            {error}
          </div>
        )}

        {retrievalMessage && (
          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4">
            {retrievalMessage}
          </div>
        )}

        {polling && !credentials && (
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-4">Processing Payment</h2>
            <p className="text-gray-600">Please wait while we process your payment and generate your credentials...</p>
          </div>
        )}

        {credentials && credentials.status === 'complete' && (
          <div>
            <h2 className="text-2xl font-bold mb-4">Your Login Credentials</h2>
            <TemporaryLoginCredentials
              username={credentials.username}
              walletAddress={credentials.wallet_address}
              tempPassword={credentials.temp_password}
            />
            <button
              onClick={handleLogin}
              className="w-full bg-purple-600 text-white p-2 rounded mt-4 hover:bg-purple-700"
            >
              Login
            </button>
          </div>
        )}

        {!polling && !credentials && !error && (
          <div>
            {isRetrieving ? (
              <form onSubmit={handlePasswordRetrieval} className="space-y-4">
                <h2 className="text-xl font-bold mb-4">Retrieve Password</h2>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="w-full p-2 border rounded"
                  required
                />
                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white p-2 rounded hover:bg-blue-700"
                >
                  Send Recovery Email
                </button>
                <button
                  type="button"
                  onClick={togglePasswordRetrieval}
                  className="w-full text-blue-600 p-2 hover:underline"
                >
                  Back to Login
                </button>
              </form>
            ) : (
              <div className="text-center">
                <p className="text-gray-600 mb-4">No active session found.</p>
                <button
                  onClick={togglePasswordRetrieval}
                  className="text-blue-600 hover:underline"
                >
                  Forgot Password?
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;